<template>
  <div>
    <div class="data-form">
      <ValidationObserver ref="importForm">
        <div class="row">
          <div class="col-12">
            <ValidationProvider name="file" rules="required" v-slot="{errors}">
              <b-form-group :label="$t('excel_file')">
                <div class="d-flex custom-file-upload">
                  <b-form-file
                      v-model="defineForm.file"
                      :state="errors[0] ? false : null"
                      :placeholder="$t('select_file')"
                      :drop-placeholder="$t('drop_file')"
                      ref="fileInput"
                      multiple></b-form-file>
                  <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                </div>


                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                <b-form-group :label="$t('excel_format')" class="mt-3">
                  <div class="alert alert-info">
                    {{ $t('house_room_excel') }}
                  </div>
                </b-form-group>
              </b-form-group>
            </ValidationProvider>
          </div>
          <div class="col-12">
            <b-button variant="success" @click="sendForm">{{ $t('save') }}</b-button>
          </div>

        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import HousingRoom from "@/services/HousingRoomServices";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      defineForm: {
        file: null
      },
      processing: false
    }
  },
  methods: {
    async sendForm() {
      const isValid = await this.$refs.importForm.validate();
      if (isValid) {
        let formData = new FormData();
        formData.append('file', this.defineForm.file[0])
        HousingRoom.importExcel(formData)
            .then(response => {
              this.$toast.success(this.$t('api.' + response.data.message))
              this.$router.push('/job/logs/detail/' + response.data.data.log_id)
            })
            .catch(e => {
              this.showErrors(e, this.$refs.importForm)
            })
            .finally(() => {
              this.processing = false
            })

      }
    }
  }
}
</script>
