<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="passport_number"
                                        rules=""
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('passport_number')">
                            <b-form-input
                                v-model="formData.passport_number"
                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="passport_country_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('passport_country')">
                            <country-selectbox v-model="formData.passport_country_id"
                                               :validateError="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="formData.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="surname" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="formData.surname">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="gender" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox
                                v-model="formData.gender"
                                :validateError="errors[0]"></gender-selectbox>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="email" rules="required|email" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('email')">
                            <b-form-input v-model="formData.email">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="mobile_number" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('mobile_number')">
                            <b-form-input v-model="formData.mobile_tel"
                                          v-mask="'+1 (###)-###-####'"
                                          :state="errors[0] ? false :null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>


                    <ValidationProvider
                        name="name"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('address')">

                            <b-form-input
                                v-model="formData.address"
                                :class="errors[0] ? 'is-invalid' : ''"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="address_city_id" rules="required"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('state')">
                            <city-selectbox
                                :validateError="errors[0]"
                                v-model="formData.address_city_id">

                            </city-selectbox>
                        </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider name="address_district_id" rules="required"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('city')">
                            <district-outline-selectbox
                                :city_id="formData.address_city_id"
                                :validateError="errors[0]"
                                v-model="formData.address_district_id">
                            </district-outline-selectbox>
                        </b-form-group>
                    </ValidationProvider>


                    <ValidationProvider name="address_zip_code" rules="required"
                                        v-slot="{valid, errors}">
                        <b-form-group :label="$t('zip_code')">
                            <b-form-input
                                :state="errors[0] ? false : (valid ? true : null)"
                                v-model="formData.address_zip_code"></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]"
                                                     v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HousingTenantServices from "@/services/HousingTenantServices";
import HouseBuildingSelectbox from "@/components/interactive-fields/HouseBuildingSelectbox.vue";
import CitySelectbox from "@/components/interactive-fields/CitySelectbox.vue";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox.vue";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox.vue";

export default {
    components: {
        GenderSelectbox,
        DistrictOutlineSelectbox,
        CitySelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {
                name: null,
                address: null
            },
        }
    },
    methods: {
        async storeForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                HousingTenantServices.store(this.formData)
                    .then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
